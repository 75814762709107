<template>
  <v-dialog
    :value="openedValue"
    max-width="700px"
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <v-card class="pa-5">
      <v-card-text>
        <v-row>
          <v-col>
            <p>
              Vous avez acceptez cette demande de retour.
            </p>
            <p>
              Souhaitez vous remettre en ligne cette pièce ?
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-radio-group
              v-model="restock"
            >
              <v-radio
                v-for="choice in choices"
                :key="choice.value"
                :label="choice.label"
                :value="choice.value"
              />
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-if="warningOrderItem">
          <v-col>
            <v-icon
              small
              color="orange"
            >
              mdi-alert
            </v-icon>
            <span class="pl-2 orange--text">{{ $t('views.return_request.message.accept_warning') }}</span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          color="red darken-2"
          @click="close()"
        >
          <v-icon left>
            mdi-cancel
          </v-icon>{{ $t('forms.buttons.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          raised
          color="green darken-1"
          class="white--text"
          @click="submit()"
        >
          <v-icon left>
            mdi-check-outline
          </v-icon>{{ $t('forms.buttons.validate') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'AcceptModal',
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    warningOrderItem: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      restock: true,
      choices: [
        { label: 'Oui', value: true },
        { label: 'Non', value: false },
      ],
    }
  },
  computed: {
    openedValue: {
      get () { return this.opened  },
      set (value) { this.$emit('update:opened', value) },
    },
  },
  methods: {
    close() {
      this.restock = true
      this.loading = false
      this.openedValue = false
    },
    submit() {
      this.$emit('submited', {
        restock: this.restock,
      })
      this.close()
    },
  },
}
</script>
