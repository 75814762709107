<template>
  <v-list-item
    three-line
  >
    <v-list-item-avatar
      tile
      size="90"
    >
      <v-img :src="product.photo_url" />
    </v-list-item-avatar>

    <v-list-item-content
      class="pa-2"
    >
      <v-list-item-title
        class="font-weight-bold pb-3"
      >
        {{ product.name }}
      </v-list-item-title>
      <v-col
        cols="12"
        md="4"
        sm="12"
      >
        <v-row class="lh_product__detail py-2">
          <span class="pr-2">Boutique : </span><span>{{ store.name }}</span>
        </v-row>
        <v-row class="lh_product__detail py-2">
          <span class="pr-2">Tailles : </span><span>{{ product.sizes }}</span>
        </v-row>
        <v-row class="lh_product__detail py-2">
          <span class="pr-2">Poids (g) : </span><span>{{ product.weight }}</span>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="4"
        sm="12"
      >
        <v-row class="lh_product__detail py-2">
          <span class="pr-2">Sku : </span><span>{{ product.sku }}</span>
        </v-row>
        <v-row class="lh_product__detail py-2">
          <span class="pr-2">EAN : </span><span>{{ product.ean_code }}</span>
        </v-row>
        <v-row class="lh_product__detail py-2">
          <span class="pr-2">Référence Externe : </span><span>{{ product.external_reference }}</span>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="4"
        sm="12"
      >
        <slot />
      </v-col>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'ProductDetail',
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    store: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selected: false,
    }
  },
}
</script>

<style scoped>
.lh__product__detail span:first-child{
  font-weight: bolder;
}
</style>
