<template>
  <Page
    :mounted.sync="mounted"
  >
    <template v-slot:title>
      <v-icon class="mr-2">
        mdi-swap-horizontal
      </v-icon>
      Retour :
      <span v-if="item?.id">
        {{ item.id }}
      </span>
      <ItemReturnStatusChip :status="item.status" />
      <v-tooltip
        v-if="item.has_delivery_delay_warning"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon
              color="orange"
            >
              mdi-clock-alert
            </v-icon>
          </v-btn>
        </template>
        <span>Délai important depuis la réception du retour</span>
      </v-tooltip>
    </template>
    
    <template v-slot:content>
      <v-card
        color="transparent"
        flat
      >
        <v-overlay
          :value="loading"
        >
          <v-progress-circular
            indeterminate
            size="64"
          />
        </v-overlay>
        <v-card-text>
          <v-row v-if="item.shipment">
            <v-col
              cols="12"
              md="6"
              sm="12"
            >
              <v-card>
                <v-card-title>
                  Client
                </v-card-title>
                <v-card-text>
                  <AddressDetail
                    :address="item.shipment.sender_address"
                    :email="item.shipment.sender_email"
                    :phone="item.shipment.sender_phone"
                  />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              md="6"
              sm="12"
            >
              <v-card>
                <v-card-title>
                  Adresse de retour
                </v-card-title>
                <v-card-text>
                  <AddressDetail
                    :address="item.shipment.recipient_address"
                    :email="item.shipment.recipient_email"
                    :phone="item.shipment.recipient_phone"
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-card>
                <v-card-title>
                  Détail
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="12"
                      md="8"
                      sm="12"
                    >
                      <v-simple-table class="lh_item-return__details">
                        <tbody>
                          <tr>
                            <td>
                              Validation
                            </td>
                            <td>
                              <template v-if="item.validation">
                                <template v-if="item.validation === 'accepted'">
                                  ✓
                                </template>
                                <template v-else-if="item.validation === 'accepted'">
                                  ✕
                                </template>
                              </template>
                              <template v-else>
                                -
                              </template>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Type de retour
                            </td>
                            <td>
                              {{
                                item.return_request.free
                                  ? $t('views.return_request.free.true')
                                  : $t('views.return_request.free.false')
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Motifs de retour
                            </td>
                            <td>
                              {{ item.comment }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Commande
                            </td>
                            <td>
                              <a
                                :href="`/tracking/list?order_item%3Aorder_reference=${item.order_item.order_reference}`"
                                class="pr-1"
                                style="display: inline-block"
                              >
                                {{ item.order_item.order_reference }}
                              </a>
                              <v-tooltip
                                v-if="item.order_item_incoherent"
                                bottom
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon
                                      small
                                      color="orange"
                                    >
                                      mdi-alert
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t('views.return_request.message.order_item_incoherent') }}</span>
                              </v-tooltip>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Date de commande
                            </td>
                            <td>
                              {{ item.order_item.created_datetime | date }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Notes
                            </td>
                            <td>
                              <Notes
                                v-if="item.id"
                                :id="item.id"
                                :type="'Return'"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                      sm="12"
                    >
                      <v-list
                        dense
                        style="text-align: right"
                      >
                        <v-list-item
                          v-for="(action, index) in supportedActions"
                          :key="index"
                        >
                          <v-list-item-title>
                            <v-btn
                              text
                              small
                              color="blue lighten-1"
                              @click="handleAction(action)"
                            >
                              {{ $t('views.return_request.buttons.' + action) }}
                            </v-btn>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-card>
                <v-card-text>
                  <ProductDetail
                    :product="item.order_item.product"
                    :store="item.order_item.store"
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <AcceptModal
        :opened.sync="modal.accept.opened"
        :warning-order-item="item.order_item_incoherent"
        @submited="performAction('accept', { restock: $event.restock} )"
      />
      <Snackbar />
    </template>
  </Page>
</template>

<script>
import AcceptModal from '@/views/Returns/AcceptModal.vue'
import AddressDetail from '@/components/core/AddressDetail.vue'
import ItemReturnStatusChip from '@/components/base/Return/ItemReturnStatusChip'
import Notes from '@/components/core/Notes.vue'
import Page from '@/components/core/Page.vue'
import ProductDetail from '@/components/core/ProductDetail.vue'
import Snackbar from '@/components/core/Snackbar.vue'
import snackbarMixin from '@/mixins/snackbar'

export default {
  name: 'ReturnEdit',
  components: {
    Page,
    AcceptModal,
    AddressDetail,
    Notes,
    Snackbar,
    ItemReturnStatusChip,
    ProductDetail,
  },
  mixins: [snackbarMixin],
  data() {
    return {
      itemReturnId: this.$route.params.id,
      mounted: false,
      request: null,
      loading: false,
      item: {},
      modal: {
        accept: {
          opened: false,
        },
      },
    }
  },
  computed: {
    supportedActions() {
      return (this.item.supported_actions ?? []).concat(this.item.return_request?.document?.supported_actions ?? [])
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    cancel() {
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
    load() {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }

      this.loading = true
      this.$axios.get('item_returns/' + this.itemReturnId, { cancelToken: axiosSource.token })
        .then((response) => {
          this.item = response.data
        })
        .catch((error) => {
          const error_message = error.response.data['hydra:description']
              ?? error.response.data['detail']
              ?? 'No context'
          this.snackbarError(this.$i18n.t('views.return_request.message.failed', { error: error_message }))
        })
        .finally(() => {
          this.request = null
          this.loading = false
          this.mounted = true
        })
    },

    async handleAction(action) {
      if (action === 'accept') {
        this.modal.accept.opened = true

        return
      }

      await this.performAction(action)
    },
    async performAction(action, params = {}) {
      if (action === 'download') {
        window.open(this.item.return_request.document.url, '_blank')

        return
      }

      if (action === 'refund') {
        const params = new URLSearchParams({
          reason: 'PARCEL_RETURNS',
          orderItemToRefund: this.item.order_item_id,
        })
        window.open(
          process.env.VUE_APP_BO_BASE_URL + '/orders/' + this.item.return_request.order_id + '?' + params.toString()
        )
      }

      try {
        await this.$axios.post(`item_returns/${this.item.id}/${action}`, params)
        this.snackbarSuccess(
          this.$i18n.t('views.return_request.message.success')
        )
      } catch (error) {
        this.snackbarError(this.$i18n.t('views.return_request.message.failed'))
      } finally {
        this.load()
      }
    },
  },
}
</script>

<style scoped>
.lh_item-return__details tr td:first-child {
  font-weight: bolder;
}

</style>
